<template>
    <div class="container mb-5 p-0 m-0 my-page-sp">
        <breadcrumb class="ml-2" :breadcrumb="breadcrumbItems"/>
        <div class="container">
            <Information />
            <div class="row mypage-schedule mt-3">
                <div class="col-md-12 col-lg-3">
                    <h2 class="sub-title">{{ $t("mypage.calendar") }}</h2>
                </div>
                <div class="col-md-12 col-lg-3">
                    <LessonSchedule />
                </div>
            </div>
        </div>
        <MetaTags 
            title="マイページ｜ResMom相談online"
            description="リセマム相談オンラインのマイページです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>

import Information from './components/information.vue';
import LessonSchedule from "@modules/sp/mypage/dashboard/components/lesson-schedule.vue";

export default {
    data() {
        return {
            isVisible: false,
            breadcrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page#",
                },
            ],
            lessonSchedule: [],
        };
    },

    components: {
        LessonSchedule,
        Information,
    }
};
</script>
<style lang="scss">
.my-page {
    width: 100% !important;
}

</style>