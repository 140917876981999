<template>
    <VCalendar class="lesson-calendar" :options="calendarOptions" ref="calendar" />
</template>
<script>
import VCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {mapActions, mapGetters} from "vuex";
export default {
    data() {
        return {
            calendarApi: null,
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                headerToolbar: {
                    start: "prevBtn",
                    center: "title",
                    end: "nextBtn",
                },
                eventClick: this.handleEventClick,
                locale: this.$i18n.locale,
                events: [],
                dayMaxEvents: 2,
                eventColor: "#fff",
                eventTextColor: "#000",
                themeSystem: "bootstrap",
                customButtons: {
                    prevBtn: {
                        text: '<',
                        click: this.handlePrevButton,
                    },
                    nextBtn: {
                        text: ">",
                        click: this.handleNextButton
                    }
                }
            },
        }
    },
    computed: {
        // Map Vuex getters to computed properties
        ...mapGetters({
            getMypageScheduleLesson: "mypage/getMypageScheduleLesson",
        }),
    },

    async beforeMount() {
        try {
            await this.fetchMypageScheduleLesson();
            this.eventResource(this.getMypageScheduleLesson);
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        eventResource(data) {
            this.calendarOptions.events.length = 0;
            data.map((item) => {
                this.calendarOptions.events.push({
                    id: item.id,
                    title: item.time + ' ' + item.title,
                    start: item.date,
                })
            })
        },

        ...mapActions({
            fetchMypageScheduleLesson: "mypage/fetchMypageScheduleLesson",
        }),

        handleEventClick: function (arg) {
            if (arg.event.id) {
                this.$router.push("/my-page/list-lesson");
            }
        },

        async handlePrevButton(){
            this.calendarApi.prev();
            let date = new Date(this.calendarApi.getDate());
            let month = date.getMonth() + 1;
            await this.fetchMypageScheduleLesson({
                month: month
            })

            this.calendarOptions.events.length = 0;
            this.getMypageScheduleLesson.map( function (item){
                return this.calendarOptions.events.push({
                    id: item.id,
                    title: item.title,
                    start: item.date,
                })
            })

            // this.eventResource(this.getMypageScheduleLesson);
        },

        async handleNextButton(){
            this.calendarApi.next();
            let date = new Date(this.calendarApi.getDate());
            let month = date.getMonth() + 1;
            await this.fetchMypageScheduleLesson({
                month: month
            })

            this.calendarOptions.events.length = 0;
            this.getMypageScheduleLesson.map( function (item){
                return this.calendarOptions.events.push({
                    id: item.id,
                    title: item.time + ' ' + item.title,
                    start: item.date,
                })
            })

            // this.eventResource(this.getMypageScheduleLesson);
        }
    },

    mounted() {
        this.calendarApi = this.$refs.calendar.getApi();
    },

    components: {
        VCalendar
    },
}
</script>

<style lang="scss">
.my-page {
    width: 100% !important;
}
.lesson-calendar {
    & .fc-day {
        & a {
            color: #000;
            font-weight: normal;
            font-size: 13px;
        }
    }
    .fc-day-sun {
        & a {color: red;}
    }
    .fc-day-sat {
        & a {color: blue;}
    }
    .fc-view-harness {
        height: 28rem !important;
    }
    a.fc-event:hover{
        cursor: pointer;
    }
}
</style>